<template>
    <div>
        <v-container class="my-5">
            <v-row>
                <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="date"
                    persistent
                    width="350px"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-model="date"
                            readonly
                            v-on="on"
                            color="indigo"
                            dark
                            class="mr-5 ml-3 mb-4"
                        >
                            {{ date }}
                        </v-btn>
                    </template>

                    <v-date-picker
                        v-model="date"
                        scrollable
                        type="month"
                        @change="dateChange(date)"
                    >
                        <v-spacer></v-spacer>

                        <v-btn text color="primary" @click="modal = false">
                            Anuluj
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                        >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-row>

            <v-row>
                <v-col>
                    <v-card class="mx-auto">
                        <v-card-title>{{ calcFullRate }} zł</v-card-title>
                        <v-card-subtitle class="text-start"
                            >Twoje przychody</v-card-subtitle
                        >
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="mx-auto">
                        <v-card-title>{{ late }} zł</v-card-title>
                        <v-card-subtitle class="text-start"
                            >Twoje spóźnienia</v-card-subtitle
                        >
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="mx-auto">
                        <v-card-title
                            >{{ calcFullRate - late }} zł</v-card-title
                        >
                        <v-card-subtitle class="text-start"
                            >Twój dochód</v-card-subtitle
                        >
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import io from "socket.io-client";

const token = localStorage.getItem('user');

export default {
    data() {
        return {
            tasks: [],
            socket: io(this.url),
            date: this.$route.params.month,
            id: this.$route.params.id,
            modal: false,
        };
    },
    methods: {
        getPosts() {
            this.$axios
                .get(
                    `${this.url}api/tasks/payment/copywriter/${
                        this.date
                    }/${localStorage.getItem("id")}`, { headers: { 'x-access-token': token }})
                .then((resp) => {
                    this.tasks = resp.data;
                });
        },
        // New
        dateChange(date) {
            this.$router.push(
                `../../../copywriter/payments/${date}/${localStorage.getItem(
                    "id"
                )}`
            );
            this.getPosts();
        },
    },
    created() {
        this.getPosts();

        this.socket.on("newTaskAdded", () => {
            this.getPosts();
        });

        this.socket.on("taskPaidUpdate", () => {
            this.getPosts();
        });

        this.socket.on("taskDeleted", () => {
            this.getPosts();
        });
    },
    computed: {
        calcFullRate() {
            let allTasks = this.tasks;
            let result = [];

            result = allTasks.reduce(function (previousValue, currentValue) {
                return previousValue + currentValue.copyRate;
            }, 0);

            return result.toFixed(2);
        },
        late() {
            let allTasks = this.tasks;
            let result = [];

            result = allTasks.reduce(function (previousValue, currentValue) {
                if (currentValue.copyLate) {
                    return (
                        previousValue + parseFloat(currentValue.copyLate) * 50
                    );
                }
                return previousValue;
            }, 0);

            return result.toFixed(2);
        },
    },
};
</script>
